<template>
  <div class="article-wrapper">
    <div class="article-header">
      <div class="main-title">Articles</div>
      <div style="display: flex; gap: 10px">
        <el-input
          v-model="search"
          placeholder="Search articles"
          prefix-icon="el-icon-search"
          clearable
          @clear="handleClearSearch"
          @input="handleSearch"
        ></el-input>
        <el-button type="primary" round @click="addArticleDialogVisible = true"
          >Add new article</el-button
        >
      </div>
    </div>
    <div class="article-body">
      <el-table :data="articles" v-loading="isLoading">
        <el-table-column prop="title" label="Title" width="200"></el-table-column>
        <el-table-column prop="tags" label="Tags" width="500">
          <template slot-scope="scope">
            <div>
              <div style="margin-right: 30px">
                <div style="margin-bottom: 10px" v-for="(tags, name) in scope.row.tags" :key="name">
                  <span>{{ name }} : </span>
                  <el-tag v-for="(tag, index) in tags" :key="index" style="margin-right: 10px">{{
                    tag
                  }}</el-tag>
                </div>
              </div>
              <el-button
                size="mini"
                type="primary"
                @click="handleOpenTagEditor(scope.row)"
                v-if="isAbleToEdit(scope.row)"
              >
                <i class="el-icon-edit"></i>
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Actions" width="500">
          <template slot-scope="scope">
            <div class="table-wrapper" v-if="isAbleToEdit(scope.row)">
              <el-button
                type="primary"
                size="small"
                @click="openStatusDialog(scope.row)"
                :loading="isFetchDocumentStatus"
                :disabled="isFetchDocumentStatus"
                >Status</el-button
              >
              <el-button
                v-if="scope.row.content?.length > 0"
                icon="el-icon-upload"
                @click="handleUploadArticle(scope.row.id)"
                type="primary"
                size="small"
                >Upload</el-button
              >

              <el-button
                icon="el-icon-edit"
                @click="handleEditArticle(scope.row)"
                type="warning"
                size="small"
              >
                Edit
              </el-button>
              <el-button
                icon="el-icon-delete"
                size="small"
                type="danger"
                @click="handleDeleteArticle(scope.row.id)"
                >Delete</el-button
              >
            </div>
            <div class="table-wrapper" v-else>
              <!-- View document button -->
              <el-button
                type="primary"
                size="small"
                @click="openStatusDialog(scope.row)"
                :loading="isFetchDocumentStatus"
                :disabled="isFetchDocumentStatus"
                >Status</el-button
              >
              <el-button @click="handleEditArticle(scope.row, false)" type="warning" size="small">
                Details
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="article-footer" v-if="articlesLength > 0 && !inSearchMode">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="articlesLength"
        :page-size="5"
        :pager-count="5"
        :current-page.sync="currentPage"
        @current-change="handleCurrentPageChange"
      ></el-pagination>
    </div>
    <Editor
      v-if="editorVisible"
      :article="selectedArticle"
      :editorVisible="editorVisible"
      :isEditMode="isEditMode"
      @updateEditorVisible="updateEditorVisible"
      @handleUpdatedArticle="handleUpdatedArticle"
      :isEditorDialogLoading="isEditorDialogLoading"
    />
    <AddArticle
      v-if="addArticleDialogVisible"
      :addArticleDialogVisible="addArticleDialogVisible"
      @updateEditorVisible="updateEditorVisible"
      @handleCreateArticle="handleCreateArticle"
      :isArticleDialogLoading="isArticleDialogLoading"
    />
    <DocumentStatusModal
      v-if="statusDialogVisible && isSelectedDocumentUpload"
      :statusDialogVisible="statusDialogVisible"
      @updateStatusDialogVisible="updateStatusDialogVisible"
      @refreshDocumentStatus="refreshDocumentStatus"
      :selectedDocumentStatus="selectedDocumentStatus"
      :selectedDocument="selectedArticle"
      :isRefreshingStatus="isRefreshingStatus"
    />

    <document-status-not-upload-modal
      v-else-if="statusDialogVisible && !isSelectedDocumentUpload"
      @updateStatusDialogVisible="updateStatusDialogVisible"
      :statusDialogVisible="statusDialogVisible"
    />

    <TagEditor
      :shouldShowTagEditor="shouldShowTagEditor"
      :selectedArticle="selectedArticle"
      :isLoading="isLoading"
      @handleCloseTagEditor="handleCloseTagEditor"
      @handleUpdateArticleTag="handleUpdateArticleTag"
    />
  </div>
</template>

<script>
import Editor from "./Editor.vue";
import AddArticle from "./AddArticle.vue";
import DocumentStatusModal from "../Documents/DocumentStatusModal.vue";
import DocumentStatusNotUploadModal from "../Documents/DocumentStatusNotUploadModal.vue";
import TagEditor from "./TagEditor.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Editor,
    AddArticle,
    DocumentStatusModal,
    DocumentStatusNotUploadModal,
    TagEditor,
  },
  data() {
    return {
      editorVisible: false,
      addArticleDialogVisible: false,
      selectedArticle: {},
      articlesLength: 0,
      isArticleDialogLoading: false,
      isEditorDialogLoading: false,
      isLoading: false,
      selectedDocumentStatus: null,
      isFetchDocumentStatus: false,
      isRefreshingStatus: false,
      statusDialogVisible: false,
      isSelectedDocumentUpload: false,
      shouldShowTagEditor: false,
      currentPage: 1,
      search: "",
      inSearchMode: false,
      isEditMode: false,
      allowEditRoles: ["admin", "genai-editor"],
    };
  },
  computed: {
    ...mapGetters(["getUserAuthentication"]),
    articles() {
      const articles = _.cloneDeep(this.$store.state.genai.genai_articles);
      articles.forEach((article) => {
        if (article.tags) {
          article.tags = JSON.parse(article.tags);
        } else {
          article.tags = {};
        }
      });
      return articles;
    },
    userProfile() {
      return this.$store.state.profile;
    },
  },
  methods: {
    handleEditArticle(article, isEdit = true) {
      this.selectedArticle = article;
      this.editorVisible = true;
      this.isEditMode = isEdit;
    },
    updateEditorVisible({ dialog, status }) {
      switch (dialog) {
        case "editor":
          this.editorVisible = status;
          break;
        case "addArticle":
          this.addArticleDialogVisible = status;
          break;
        default:
          break;
      }
    },
    async handleCreateArticle(article) {
      this.isArticleDialogLoading = true;
      const result = await this.$store.dispatch("GENAI_CREATE_ARTICLE", article);
      this.isArticleDialogLoading = false;
      this.addArticleDialogVisible = false;
      if (result.success) {
        this.$message({
          type: "success",
          message: "Create article successfully!",
        });
      } else {
        this.$message({
          type: "error",
          message: "Failed to create article",
        });
      }
    },
    async openStatusDialog(document) {
      try {
        this.isLoading = true;
        this.selectedArticle = document;
        if (document.isUploaded) {
          const documentStatus = await this.$store.dispatch("GENAI_GET_DOCUMENT_STATUS", {
            documentId: document.id,
          });
          this.selectedDocumentStatus = documentStatus;
        }
        this.statusDialogVisible = true;
        this.isSelectedDocumentUpload = document.isUploaded;
        this.isLoading = false;
      } catch (error) {
        this.$notify({
          title: "Error",
          message: "There was an error getting the document status",
          type: "error",
        });
        this.isLoading = false;
      }
    },
    async refreshDocumentStatus(documentId) {
      this.isRefreshingStatus = true;
      const documentStatus = await this.$store.dispatch("GENAI_GET_DOCUMENT_STATUS", {
        documentId,
      });
      this.selectedDocumentStatus = documentStatus;
      this.isRefreshingStatus = false;
      return documentStatus;
    },
    async handleUploadArticle(id) {
      this.$confirm("Are you sure to upload this article?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(async () => {
          this.isLoading = true;
          const uploadedBy = this.userProfile.email;
          const result = await this.$store.dispatch("GENAI_UPLOAD_ARTICLE", {
            id,
            uploadedBy,
            page: this.currentPage,
          });
          if (result.success) {
            this.$message({
              type: "success",
              message: "Upload successfully!",
            });
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$notify({
            title: "Error",
            message: "There was an error uploading the article",
            type: "error",
          });
        });
    },
    handleDeleteArticle(id) {
      this.$confirm("Are you sure to delete this article?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(async () => {
          this.isLoading = true;
          const result = await this.$store.dispatch("GENAI_DELETE_ARTICLE", { id });
          if (result.success) {
            this.$message({
              type: "success",
              message: "Delete successfully!",
            });
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$notify({
            title: "Error",
            message: "There was an error deleting the article",
            type: "error",
          });
        });
    },
    async handleUpdatedArticle(newArticle) {
      this.isLoading = true;
      this.isEditorDialogLoading = true;
      const result = await this.$store.dispatch("GENAI_UPDATE_ARTICLE", {
        newArticle,
        page: this.currentPage,
      });
      if (result.success) {
        this.isEditorDialogLoading = false;
        this.editorVisible = false;
        this.isLoading = false;
        this.$message({
          type: "success",
          message: "Update successfully!",
        });
      } else {
        this.isLoading = false;
        this.isEditorDialogLoading = false;
        this.$notify({
          title: "Error",
          message: "There was an error updating the article",
          type: "error",
        });
      }
    },
    async handleCurrentPageChange(page) {
      this.isLoading = true;
      await this.$store.dispatch("GENAI_GET_ARTICLES", { page });
      this.isLoading = false;
    },
    updateStatusDialogVisible(value) {
      this.statusDialogVisible = value;
    },
    handleOpenTagEditor(article) {
      this.shouldShowTagEditor = true;
      this.selectedArticle = article;
    },
    handleCloseTagEditor() {
      this.shouldShowTagEditor = false;
    },
    async handleUpdateArticleTag({ newArticle, oldArticle }) {
      this.isLoading = true;
      const oldArticleId = oldArticle.id;
      if (newArticle.tags) {
        newArticle.tags = JSON.stringify(newArticle.tags);
      }
      const result = await this.$store.dispatch("GENAI_CREATE_ARTICLE", newArticle);
      if (result.success) {
        await this.$store.dispatch("GENAI_DELETE_ARTICLE", { id: oldArticleId });
        this.shouldShowTagEditor = false;
        this.isLoading = false;
        this.$message({
          type: "success",
          message: "Update article successfully!",
        });
        this.currentPage = 1;
        this.$store.dispatch("GENAI_GET_ARTICLES", { page: 1 });
      } else {
        this.$message({
          type: "error",
          message: "Failed to update article",
        });
        this.isLoading = false;
      }
    },
    handleSearch: _.debounce(async function () {
      try {
        this.isLoading = true;
        if (this.search === "") {
          await this.$store.dispatch("GENAI_GET_ARTICLES", { page: 1 });
          this.inSearchMode = false;
          this.isLoading = false;
          return;
        }
        await this.$store.dispatch("GENAI_SEARCH_DOCUMENTS", {
          search: this.search,
          documentType: "articles",
        });
        this.isLoading = false;
        this.inSearchMode = true;
      } catch (error) {
        this.inSearchMode = false;
        this.isLoading = false;
        this.$notify({
          title: "Error",
          message: "There was an error searching the articles",
          type: "error",
        });
      }
    }, 800),

    async handleClearSearch() {
      try {
        this.search = "";
        await this.$store.dispatch("GENAI_GET_ARTICLES", { page: 1 });
        this.isLoading = false;
        this.inSearchMode = false;
      } catch (error) {
        this.isLoading = false;
        this.$notify({
          title: "Error",
          message: "There was an error clearing the search",
          type: "error",
        });
      }
    },
    isAbleToEdit(selectedArticle) {
      return (
        this.userProfile.email === selectedArticle.uploadedBy ||
        this.getUserAuthentication["roles"].some((role) => this.allowEditRoles.includes(role))
      );
    },
  },
  async mounted() {
    this.isLoading = true;
    const articles = await this.$store.dispatch("GENAI_GET_ARTICLES", { page: 1 });
    this.articlesLength = articles.count;
    this.isLoading = false;
  },
};
</script>

<style lang="scss">
.article-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.article-body {
  padding: 20px;
}
.article-wrapper {
  margin: 20px;
}

.table-wrapper {
  display: flex;
  gap: 15px;
  flex-wrap: no-wrap;

  button {
    margin: 0 !important;
  }
}
</style>
